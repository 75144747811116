<!-- System: STA
    Purpose: 1- This compoment will display Facial attendance
                reports section In table and Graph View.
             2 - Assign Team Members + Names.
             3 - Download Reports In CSV And Excel.
-->
<template>
  <div class="mt-1">
    <v-row class="d-flex justify-end">
      <v-col cols="12" sm="6" md="3" lg="3" class="mt-6">
        <!-- Icon To Switch Between Table and Chart -->
        <v-btn text @click="(chartView = false), (tableView = true)">
          <v-icon>mdi-table-large</v-icon>
        </v-btn>
        <v-btn
          text
          @click="
            chartView = true;
            (tableView = false), displayChart();
          "
        >
          <v-icon> mdi-chart-bar</v-icon>
        </v-btn>
        <!-- Export Buttons -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-10 mt-1 white--text tm-btn"
              color="#2758f6"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <span class="mdi mdi-cloud-download mr-2"></span>
              Export
            </v-btn>
          </template>
          <v-list>
            <v-list-item link>
              <v-list-item-title align="left">
                <DownloadCsv
                  :data="convertedattendanceDataForJsonDownload"
                  name="facial-attendance.csv"
                  >CSV
                </DownloadCsv>
              </v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title align="left">
                <DownloadExcell
                  :data="convertedattendanceDataForJsonDownload"
                  name="facial-attendance-excel.xls"
                  >Excel
                </DownloadExcell>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>

      <!-- Options For Employees Or Visitors etc-->
      <v-col cols="12" sm="6" md="3" lg="2" class="mt-2">
        <v-autocomplete
          outlined
          dense
          class="mt-4"
          v-model="selectedOptions"
          :items="options"
          label="Search By Filter"
          @change="getFacialData()"
        ></v-autocomplete>
      </v-col>

      <!-- Date Range Picker -->
      <v-col cols="12" sm="6" md="3" class="Date-Picker-Col">
        <label
          class="pl-3 labels d-block font-weight-thin text-left date-range-label mt-10 mt-sm-2"
          for
          id="cRange"
          >Date Range:</label
        >

        <v-menu nudge-left="0" :close-on-click="false" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-col class="d-flex flex-row-reverse">
              <v-subheader
                class="selected-date mr-4"
                v-bind="attrs"
                v-on="on"
                style="width: 100%"
                @click="checkClick"
              >
                {{ dateRangeTitle }}
                <span class="d-flex justify-start ml-auto">
                  <v-icon color="#2758F6" id="rangeBtn"
                    >mdi-calendar-range</v-icon
                  >
                </span>
              </v-subheader>
            </v-col>
          </template>

          <v-list id="custom-list-style">
            <v-list-item
              style="min-height: 30px"
              v-for="(item, index) in items"
              :key="index"
              @click="checkIfCustomDate(item)"
            >
              <v-list-item-title style="font-size: 13px" class="custom">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-dialog
          ref="dialog"
          v-model="modal_2"
          :return-value.sync="date"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-show="(modal_2_show = false)"
              v-model="date"
              label="Picker in Dialog"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" range no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeDateModal()">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              id="apply-btn"
              @click="getDataFromCustomDate()"
            >
              Apply
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <!-- Graph View For Facial Attendace -->
    <v-row v-show="chartView">
      <div id="chart"></div>
    </v-row>

    <!-- Table For Attendence Table -->
    <v-row v-show="tableView">
      <v-col cols="12">
        <!-- Table For Visitors -->
        <v-data-table
          v-if="
            selectedOptions == 'Visitor' ||
            selectedOptions == 'Member' ||
            selectedOptions == 'Known Visitor'
          "
          :headers="selectedOptions == 'Visitor' ? header : headerWithAction"
          :items="filteredData"
          :items-per-page="-1"
          class="elevation-10"
          :loading="loader"
          :mobile-breakpoint="0"
          fixed-header
          id="FacialAttendenceTable"
          height="calc(95vh - 265px)"
        >
          <template v-slot:[`body.prepend`]="{ headers }">
            <tr>
              <td class="text-start">
                <div class="inputContainer body-2">
                  <v-text-field
                    autocomplete="off"
                    v-model="nameSearch"
                    size="5"
                    placeholder="Search Name"
                  />
                </div>
              </td>
              <td class="text-start">
                <div class="inputContainer body-2">
                  <v-autocomplete
                    dense
                    class="mt-4"
                    size="2"
                    v-model="selectedOptions"
                    :items="options"
                    label="Search By Filter"
                    @change="getFacialData()"
                  ></v-autocomplete>
                </div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-start">
                <div class="inputContainer body-2">
                  <v-text-field
                    autocomplete="off"
                    v-model="locationSearch"
                    size="5"
                    placeholder="Search Office"
                  />
                </div>
              </td>
              <td></td>
            </tr>
          </template>
          <!-- Member Name / Image -->
          <template v-slot:item.name="{ item }">
            <img
              :src="`${$api_url}storage/${item.image}`"
              @error="
                $event.target.src = require('@/assets/images/no-member.svg')
              "
              alt="no image"
              class="select-image"
            />

            <span
              class="text-capitalize"
              style="position: relative; top: -6px; left: 11px"
              v-if="item.name"
            >
              {{ item.name }}
            </span>
          </template>
          <!--  type -->
          <template v-slot:item.type="{ item }">
            <span v-if="item.user_type == 'visitor'" class="text-capitalize">
              <v-chip x-small color="grey" text-color="white">Visitor</v-chip>
            </span>
            <span
              v-if="item.user_type == 'known_visitor'"
              class="text-capitalize"
            >
              <v-chip x-small color="blue" text-color="white"
                >Known Visitor</v-chip
              >
            </span>
            <span v-if="item.user_type == 'employee'" class="text-capitalize">
              <v-chip x-small color="green" text-color="white">Employee</v-chip>
            </span>
            <span v-if="item.user_type == 'member'" class="text-capitalize">
              <v-chip x-small color="blue" text-color="white">Member</v-chip>
            </span>
          </template>
          <!-- Date -->
          <template v-slot:item.date="{ item }">
            <span>
              {{ item.date }}
            </span>
          </template>
          <!-- First Check In Row -->
          <template v-slot:item.check_in="{ item }">
            <span
              class="text-capitalize cursor"
              @click="
                AssignMemberModalFunc(
                  `${$api_url}storage/${item.check_in_file_path}`,
                  'Check In Details',
                  parseDateTime(item.check_in),
                  item.device_location,
                  item.hash_id,
                  item.user_face_hash_id
                ),
                  (userType = 'Visitor' ? item.name == 'Visitor' : 'Employee')
              "
              :href="`${$api_url}storage/${item.check_in_file_path}`"
            >
              {{ parseDateTime(item.check_in) }}
            </span>
          </template>
          <!--  Check Out Row -->
          <template v-slot:item.check_out="{ item }">
            <span
              class="text-capitalize cursor"
              @click="
                AssignMemberModalFunc(
                  `${$api_url}storage/${item.check_out_file_path}`,
                  'Check Out Details',
                  parseDateTime(item.check_out),
                  item.device_location,
                  item.hash_id,
                  item.user_face_hash_id
                ),
                  (userType = 'Visitor' ? item.name == 'Visitor' : 'Employee')
              "
            >
              {{ parseDateTime(item.check_out) }}
            </span>
          </template>
          <!-- office Location -->
          <template v-slot:item.office="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ item.device_location.slice(0, 12) }}
                </span>
              </template>
              <span> {{ item.device_location }} </span>
            </v-tooltip>
          </template>
          <!-- Action Row -->
          <template v-slot:item.action="{ item }">
            <span v-if="item.user_face_hash_id">
              <span class="ml-4">
                <v-icon
                  color="error"
                  rounded
                  @click="
                    (deleteModal = true),
                      (imageToDelete = item.user_face_hash_id)
                  "
                  >mdi-delete</v-icon
                >
              </span>
            </span>
          </template>
        </v-data-table>

        <!-- Table For Members -->
        <v-data-table
          v-else
          :headers="header"
          :items="filteredData"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="name"
          show-expand
          class="elevation-10"
          id="FacialAttendenceTable"
          :loading="loader"
          :items-per-page="-1"
          :mobile-breakpoint="0"
          :fixed-header="true"
          height="calc(95vh - 265px)"
        >
          <template v-slot:[`body.prepend`]="{ headers }">
            <tr>
              <td></td>
              <td class="text-start">
                <div class="inputContainer body-2">
                  <v-text-field
                    autocomplete="off"
                    v-model="nameSearch"
                    size="5"
                    placeholder="Search Name"
                  />
                </div>
              </td>
              <td class="text-start">
                <div class="inputContainer body-2">
                  <v-autocomplete
                    dense
                    class="mt-4"
                    size="2"
                    v-model="selectedOptions"
                    :items="options"
                    label="Search By Filter"
                    @change="getFacialData()"
                  ></v-autocomplete>
                </div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-start">
                <div class="inputContainer body-2">
                  <v-text-field
                    autocomplete="off"
                    v-model="locationSearch"
                    size="5"
                    placeholder="Search Office"
                  />
                </div>
              </td>
            </tr>
          </template>
          <v-spacer></v-spacer>
          <!-- Member Name / Image / type -->
          <template v-slot:item.name="{ item }">
            <img
              :src="`${$api_url}storage/${item.image}`"
              @error="
                $event.target.src = require('@/assets/images/no-member.svg')
              "
              alt="no image"
              class="select-image"
            />
            <span
              class="text-capitalize"
              style="position: relative; top: -6px; left: 11px"
            >
              {{ item.name }}
            </span>
          </template>
          <template v-slot:item.type="{ item }">
            <span
              v-if="item.data[0].user_type == 'visitor'"
              class="text-capitalize"
            >
              <v-chip x-small color="grey" text-color="white">Visitor</v-chip>
            </span>
            <span
              v-if="item.data[0].user_type == 'known_visitor'"
              class="text-capitalize"
            >
              <v-chip x-small color="blue" text-color="white"
                >Known Visitor</v-chip
              >
            </span>
            <span
              v-if="item.data[0].user_type == 'employee'"
              class="text-capitalize"
            >
              <v-chip x-small color="green" text-color="white">Employee</v-chip>
            </span>
            <span
              v-if="item.data[0].user_type == 'member'"
              class="text-capitalize"
            >
              <v-chip x-small color="blue" text-color="white">Member</v-chip>
            </span>
          </template>
          <!-- Date -->
          <template v-slot:item.date="{ item }">
            <span>
              {{ item.data[0].date }}
            </span>
          </template>
          <!-- First Check In Row -->
          <template v-slot:item.check_in="{ item }">
            <span
              class="text-capitalize cursor"
              @click="
                AssignMemberModalFunc(
                  `${$api_url}storage/${item.data[0].check_in_file_path}`,
                  'Check In Details',
                  parseDateTime(item.data[0].check_in),
                  item.data[0].device_location,
                  item.data[0].hash_id,
                  item.data[0].user_face_hash_id
                ),
                  (userType = 'Visitor' ? item.name == 'Visitor' : 'Employee')
              "
              :href="`${$api_url}storage/${item.data[0].check_in_file_path}`"
            >
              {{ parseDateTime(item.data[0].check_in) }}
            </span>
          </template>
          <!-- First Check Out Row -->
          <template v-slot:item.check_out="{ item }">
            <span
              class="text-capitalize cursor"
              @click="
                AssignMemberModalFunc(
                  `${$api_url}storage/${item.data[0].check_out_file_path}`,
                  'Check Out Details',
                  parseDateTime(item.data[0].check_out),
                  item.data[0].device_location,
                  item.data[0].hash_id,
                  item.data[0].user_face_hash_id
                ),
                  (userType = 'Visitor' ? item.name == 'Visitor' : 'Employee')
              "
            >
              {{ parseDateTime(item.data[0].check_out) }}
            </span>
          </template>
          <!-- Location Row -->
          <template v-slot:item.office="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ item.data[0].device_location.slice(0, 12) }}
                </span>
              </template>
              <span> {{ item.data[0].device_location }} </span>
            </v-tooltip>
          </template>

          <!-- Sub Table For Attendence Of That Pirticular User -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" v-if="item.data.length > 1">
              <v-simple-table class="ml-n4">
                <template v-slot:default>
                  <tbody>
                    <tr v-for="(entry, i) in item.data" :key="i">
                      <td class="" :class="i == 0 ? 'd-none' : ''"></td>
                      <!-- User Image & Name -->
                      <td class="text-left" :class="i == 0 ? 'd-none' : ''">
                        <img
                          :src="`${$api_url}storage/${item.image}`"
                          @error="
                            $event.target.src = require('@/assets/images/no-member.svg')
                          "
                          alt="no image"
                          class="select-image"
                        />
                        <span
                          class="text-capitalize"
                          style="
                            position: relative;
                            top: -6px;
                            left: 11px;
                            width: 140px;
                          "
                        >
                          {{ item.name }}
                        </span>
                        <span
                          v-if="entry.name && entry.user_type != 'employee'"
                          class="ml-5 text-capitalize"
                          style="
                            position: relative;
                            top: -6px;
                            left: 11px;
                            width: 140px;
                          "
                        >
                          - {{ entry.name }}
                        </span>
                      </td>
                      <!-- Date -->
                      <td class="" :class="i == 0 ? 'd-none' : ''">
                        <span
                          v-if="entry.user_type == 'visitor'"
                          class="text-capitalize"
                        >
                          <v-chip x-small color="grey" text-color="white"
                            >Visitor</v-chip
                          >
                        </span>
                        <span
                          v-if="entry.user_type == 'known_visitor'"
                          class="text-capitalize"
                        >
                          <v-chip x-small color="blue" text-color="white"
                            >Known Visitor</v-chip
                          >
                        </span>
                        <span
                          v-if="entry.user_type == 'employee'"
                          class="text-capitalize"
                        >
                          <v-chip x-small color="green" text-color="white"
                            >Employee</v-chip
                          >
                        </span>
                        <span
                          v-if="entry.user_type == 'member'"
                          class="text-capitalize"
                        >
                          <v-chip x-small color="blue" text-color="white"
                            >Member</v-chip
                          >
                        </span>
                      </td>
                      <!-- Date -->
                      <td class="" :class="i == 0 ? 'd-none' : ''">
                        <span>
                          {{ entry.date }}
                        </span>
                      </td>
                      <!-- Check In Time -->
                      <td class="" :class="i == 0 ? 'd-none' : ''">
                        <span
                          class="text-capitalize cursor"
                          @click="
                            AssignMemberModalFunc(
                              `${$api_url}storage/${entry.check_in_file_path}`,
                              'Check In Details',
                              parseDateTime(entry.check_in),
                              entry.device_location,
                              entry.hash_id,
                              entry.user_face_hash_id
                            ),
                              (userType = 'Visitor'
                                ? item.name == 'Visitor'
                                : 'Employee')
                          "
                        >
                          {{ parseDateTime(entry.check_in) }}
                        </span>
                      </td>
                      <!-- Check Out Time -->
                      <td class="" :class="i == 0 ? 'd-none' : ''">
                        <a
                          class="text-capitalize cursor"
                          @click="
                            AssignMemberModalFunc(
                              `${$api_url}storage/${entry.check_out_file_path}`,
                              'Check Out Details',
                              parseDateTime(entry.check_out),
                              entry.device_location,
                              entry.hash_id,
                              entry.user_face_hash_id
                            ),
                              (userType = 'Visitor'
                                ? item.name == 'Visitor'
                                : 'Employee')
                          "
                        >
                          {{ parseDateTime(entry.check_out) }}
                        </a>
                      </td>
                      <td class="" :class="i == 0 ? 'd-none' : ''">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              {{ entry.device_location.slice(0, 12) }}
                            </span>
                          </template>
                          <span>{{ entry.device_location }} </span>
                        </v-tooltip>
                      </td>
                      <!-- Delete Row Column -->
                      <td
                        :class="i == 0 ? 'd-none' : ''"
                        v-if="entry.user_face_hash_id"
                      >
                        <span>
                          <v-icon
                            color="error"
                            rounded
                            @click="
                              (deleteModal = true),
                                (imageToDelete = entry.user_face_hash_id)
                            "
                            >mdi-delete</v-icon
                          >
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal For Assign Member  -->
    <v-dialog
      width="450"
      class="pa-0 white-bg assign-modal"
      v-model="AssignMemberModal"
      @click:outside="modal_hide"
    >
      <!-- Assign Member To Image -->
      <v-card class="assign-modal">
        <v-card-title class="headline white lighten-2 justify-center">
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn icon @click="modal_hide()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-row>
          <v-col class="d-flex justify-end">
            <v-col cols="6">
              <span class="overline"> Location : </span>
              <div>
                <v-chip v-if="location" small color="red" text-color="white">{{
                  location
                }}</v-chip>
                <v-chip v-else small color="red" text-color="white">
                  N/A
                </v-chip>
              </div>
            </v-col>
            <v-col cols="6">
              <span class="overline"> Time : </span>
              <div>
                <v-chip small color="green" text-color="white">{{
                  time
                }}</v-chip>
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row v-if="selectedOptions == 'Visitor'" class="mb-n5 mt-n8">
          <v-col class="d-flex justify-end">
            <!-- Text Field For Name -->
            <v-col cols="6">
              <v-text-field
                class="mt-2"
                outlined
                dense
                autocomplete="off"
                v-model="assignName"
                v-on:keyup.enter="updateNameModal = true"
                label="Assign Name"
              ></v-text-field>
              <div class="caption mt-n6 mr-7">Press Enter To Submit Name</div>
            </v-col>
            <v-col cols="6">
              <!-- Drop Down Of All Team Members -->
              <v-autocomplete
                class="mt-2"
                outlined
                dense
                v-model="selectedTeamMember"
                @change="updateTeamMember()"
                :items="team_members"
                item-text="name"
                item-value="hash_id"
                label="Assign Member"
              ></v-autocomplete>
            </v-col>
          </v-col>
        </v-row>
        <!-- Captured Image -->
        <div v-if="selectedImage">
          <img
            :src="selectedImage"
            class="img-fluid w-100"
            height="500"
            width="400"
          />
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog/Modal For Loader -->
    <v-dialog v-model="updating" hide-overlay persistent width="300">
      <v-card color="white">
        <v-card-text>
          Please wait
          <v-progress-linear
            indeterminate
            color="blue"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Modal For Delete User/ Visitor Row -->
    <v-dialog v-model="deleteModal" hide-overlay persistent width="300">
      <v-card>
        <v-card-title class="text-h5"> Are you sure ? </v-card-title>

        <v-card-text> you want to delete this entry. </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="deleteModal = false">
            Cancel
          </v-btn>

          <v-btn color="error darken-1" text @click="deleteVisior()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    {{ filterVisitorData }}
    <!-- Modal For User Confrimation Tag -->
    <v-dialog v-model="updateNameModal" hide-overlay persistent width="300">
      <v-card>
        <v-card-title class="text-h5"> Update Member Name</v-card-title>

        <v-card-text> Please select the type of member ? </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error darken-1" text @click="updateNameModal = false">
            Cancel
          </v-btn>

          <v-btn
            color="success darken-1"
            text
            @click="updateTeamMemberName('member')"
          >
            Member
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="updateTeamMemberName('known_visitor')"
          >
            Visitor
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApexCharts from "apexcharts";
import JsonExcel from "vue-json-excel";
import JsonCSV from "vue-json-csv";
import { mapState } from "vuex";
import { baseURL } from "@/assets/js/urls";
import moment from "moment";
export default {
  components: {
    DownloadExcell: JsonExcel,
    DownloadCsv: JsonCSV,
  },
  data() {
    return {
      chartData: "",
      chartView: false,
      tableView: true,
      expanded: [],
      nameSearch: "",
      typeSearch: "",
      locationSearch: "",
      singleExpand: false,
      loader: false,
      updating: false,
      selectedTeamMember: "",
      updateNameModal: false,
      userType: "",
      title: "",
      time: "",
      location: "",
      hash_id: "",
      user_face_hash_id: "",
      assignName: "",
      deleteModal: false,
      imageToDelete: "",
      AssignMemberModal: false,
      selectedImage: "",
      header: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Type", value: "type", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Check In", value: "check_in", sortable: false },
        { text: "Checkout", value: "check_out", sortable: false },
        { text: "Office", value: "office", sortable: false },
      ],
      headerWithAction: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Type", value: "type", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Check In", value: "check_in", sortable: false },
        { text: "Checkout", value: "check_out", sortable: false },
        { text: "Office", value: "office", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      attendanceData: "",
      data_availabe: false,
      options: ["All", "Employee", "Member", "Known Visitor", "Visitor"],
      selectedOptions: "",
      visitorDataOption: false,

      // Date Range Variables
      date: [],
      showPicker: false,
      menu: false,
      items: [
        { id: 1, title: "Today" },
        { id: 2, title: "Yesterday" },
        { id: 3, title: "Last 7 Days", value: "" },
        { id: 4, title: "Last 30 Days", value: "" },
        { id: 5, title: "This Month", value: "" },
        { id: 7, title: "Last Month", value: "" },
        { id: 6, title: "Custom Range" },
      ],
      start: moment().startOf("month"),
      end: moment().endOf("month"),
      start_date: "",
      end_date: "",
      dateRangeTitle: "",
      CustomeDate: false,
      modal_2: false,
    };
  },
  mounted() {
    /*
      0- Select Table View Default
      1- Select Option Of Employee By Default 
      2- Set The Date Range Of One Today
      3- Fetch The Table Date - Api Call
     */
    this.tableView = true;
    this.selectedOptions = this.options[0];
    this.start_date = moment().format("YYYY-MM-DD");
    this.end_date = moment().format("YYYY-MM-DD");
    this.dateRangeTitle = "Today";
    this.getFacialData();
  },
  computed: {
    ...mapState("custom", ["team_members"]),
    // ApexChart Options Properties
    chartoptions() {
      return {
        chart: {
          type: "bar",
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#247BA0", "#FF1654"],
        series: [],
        plotOptions: {
          bar: {
            columnWidth: "40%",
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            labels: {
              style: {
                colors: "#247BA0",
              },
            },
            title: {
              text: "Employees",
              style: {
                color: "#247BA0",
              },
            },
          },
          {
            opposite: true,
            axisTicks: {
              show: true,
            },
            labels: {
              style: {
                colors: "#FF1654",
              },
            },
            title: {
              text: "Visitors",
              style: {
                color: "#FF1654",
              },
            },
          },
        ],
        tooltip: {
          shared: false,
          intersect: true,
          x: {
            show: true,
          },
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40,
        },
      };
    },
    // Return Base URL
    url() {
      return baseURL.API_URL;
    },
    /*
      1- Convert The Response Into Array Of Objects,
      2- Return Fitered Array
     */
    convertedattendanceData() {
      if (this.data_availabe) {
        let dummyArray = [];
        for (let i = 0; i < Object.keys(this.attendanceData).length; i++) {
          dummyArray.push(Object(this.attendanceData[i]));
        }
        if (this.visitorDataOption) {
          let visitorArray = dummyArray.map((visitorData) => {
            return {
              name: visitorData[0].name,
              date: visitorData[0].date,
              hash_id: visitorData[0].hash_id,
              image: visitorData[0].image,
              user_type: visitorData[0].user_type,
              device_location: visitorData[0].device_location,
              data: visitorData.data,
            };
          });
          return visitorArray;
        }
        let employeeArray = dummyArray.map((employee) => {
          return {
            name: employee[0].name,
            date: employee[0].date,
            hash_id: employee[0].hash_id,
            image: employee[0].image,
            user_type: employee[0].user_type,
            device_location:
              employee[0].user_type == "visitor"
                ? ""
                : employee[0].device_location,
            data: employee.data,
          };
        });
        return employeeArray;
      }
    },
    filteredData() {
      let res = [];
      if (this.selectedOptions == "All" || this.selectedOptions == "Employee") {
        res = this.convertedattendanceData;
      } else {
        res = this.CovertedDataForVisitors;
      }
      if (res) {
        if (this.nameSearch) {
          res = res.filter((item) => {
            return item && item.name
              ? item.name.toLowerCase().includes(this.nameSearch.toLowerCase())
              : false;
          });
        }
        if (this.locationSearch) {
          // res = res.filter((element) => element.data.some((data) => data.device_location.toLowerCase().includes(this.locationSearch.toLowerCase())))
          res = res.filter((item) => {
            return item && item.device_location
              ? item.device_location
                  .toLowerCase()
                  .includes(this.locationSearch.toLowerCase())
              : false;
          });
        }
        return res;
      }
    },
    /*
      1- Convert The Response Into Array Of Objects,
      2- Return Fitered Array Of Visitors
     */
    CovertedDataForVisitors() {
      if (this.data_availabe) {
        let dummyArray = [];
        for (let i = 0; i < Object.keys(this.attendanceData).length; i++) {
          dummyArray.push(Object(this.attendanceData[i]));
        }
        if (this.visitorDataOption) {
          let visitorArray = dummyArray.map((visitorData) => {
            return visitorData.data;
          });

          return visitorArray[0];
        }
      }
    },
    /*
      1- Convert The Response Into Filtered
      2- JSON Format For CSV & Excel Download
     */
    convertedattendanceDataForJsonDownload() {
      if (this.data_availabe) {
        let dummyArray = [];
        for (let i = 0; i < Object.keys(this.attendanceData).length; i++) {
          dummyArray.push(Object(this.attendanceData[i]));
        }
        let temparr = [];
        dummyArray.map((employee) => {
          for (let i = 0; i < employee.data.length; i++) {
            temparr.push({
              Name: employee.data[i].name,
              Date: employee.data[i].date,
              Check_In: this.parseDateTime(employee.data[i].check_in),
              Check_Out: this.parseDateTime(employee.data[i].check_out),
              Device_Location: employee.data[i].device_location,
              User_Type: employee.data[i].user_type,
            });
          }
        });
        return temparr;
      }
    },
    filterVisitorData() {
      if (
        this.locationSearch != "" &&
        this.convertedattendanceData &&
        (this.selectedOptions == "All" || this.selectedOptions == "Employee")
      ) {
        this.extractVisitors(this.convertedattendanceData);
      }
    },
  },
  methods: {
    /**
     * This function is resposible for
     * extract visitors from array during
     * location search
     */
    extractVisitors(res) {
      let allmembers = res;
      let visitor = [];
      allmembers = allmembers.filter((item) => {
        if (item.name == "Visitor") {
          for (let i = 0; i < item.data.length; i++) {
            if (
              item.data[i].device_location
                .toLowerCase()
                .includes(this.locationSearch.toLowerCase())
            ) {
              visitor.push(item.data[i]);
            }
          }
          if (visitor.length >= 1) {
            let obj = {
              device_location: visitor[0].device_location,
              hash_id: Date.now(),
              image: null,
              name: "visitor",
              user_id: null,
              user_type: "visitor",
              data: visitor,
            };
            return this.filteredData.unshift(obj);
          }
          return;
        }
      });
    },
    /**
     * This function is resposible for
     * Creating ApexChart / Graph On Screen.
     */
    displayChart() {
      this.chartView = true;
      var chart = new ApexCharts(
        document.querySelector("#chart"),
        this.chartoptions
      );
      chart.render();
    },
    /**
     * This function is resposible for
     * Update Member With Any Rejistered
     * Team Member.
     */
    updateTeamMember() {
      let src = this.selectedImage;
      let payload = {
        company_id: this.$route.params.id,
        member_id: this.selectedTeamMember,
        imageLink: src,
        user_type: "employee",
        hash_id: this.hash_id,
      };
      this.updating = true;
      this.$store
        .dispatch("custom/save_member_face", payload)
        .then((Response) => {
          this.updating = false;
          this.AssignMemberModal = false;
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Image Updated Successfully",
            snackbarColor: "green",
          });
          this.selectedTeamMember = "";
          this.getFacialData();
        })
        .catch((err) => {
          this.updating = false;
          this.AssignMemberModal = false;
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Error Updating Image",
            snackbarColor: "red",
          });
        });
    },
    /**
     * This function is resposible for
     * Assign Multiple Values Of Selected Row
     * to Data Properties.
     */
    AssignMemberModalFunc(
      src,
      my_title,
      time,
      location,
      hash_id,
      user_face_hash_id
    ) {
      this.title = my_title;
      this.time = time;
      this.location = location;
      this.hash_id = hash_id;
      this.user_face_hash_id = user_face_hash_id;
      this.selectedImage = src;
      if (this.selectedImage != "https://st.stafftimerapp.com/storage/null") {
        this.AssignMemberModal = true;
      }
    },
    /**
     * This function is resposible for
     * Update User Name / Tag
     */
    updateTeamMemberName(user_type) {
      let payload = {
        name: this.assignName,
        imageLink: this.selectedImage,
        user_type: user_type,
        company_id: this.$route.params.id,
        user_face_hash_id: this.user_face_hash_id,
        hash_id: this.hash_id,
      };
      this.updating = true;
      this.$store
        .dispatch("custom/save_member_face", payload)
        .then((Response) => {
          this.updating = false;
          this.loader = false;
          this.assignName = "";
          this.AssignMemberModal = false;
          this.updateNameModal = false;
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Member Updated Successfully",
            snackbarColor: "green",
          });
          this.getFacialData();
        })
        .catch((err) => {
          this.updating = false;
          this.updateNameModal = false;
          this.assignName = "";
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Error Updating Image",
            snackbarColor: "red",
          });
        });
    },
    /**
     * This function is resposible for
     * Delete Row From Vistors
     */
    deleteVisior() {
      let payload = {
        user_face_hash_id: this.imageToDelete,
        company_id: this.$route.params.id,
      };
      this.$store
        .dispatch("custom/delete_facial_attandence", payload)
        .then((Response) => {
          this.loader = false;
          this.deleteModal = false;
          this.getFacialData();
          this.$root.$emit("snack_bar", {
            show: true,
            message: "User Face Deleted Successfully",
            snackbarColor: "green",
          });
        })
        .catch((err) => {
          this.updating = false;
          this.deleteModal = false;
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Error Deleting Image",
            snackbarColor: "red",
          });
        });
    },
    /**
     * This function is resposible for
     * hide Assign Team Member Modal
     */
    modal_hide() {
      this.AssignMemberModal = false;
      this.selectedTeamMember = "";
    },
    /**
     * This function is resposible for
     * remove date from the time stamp
     * and convert it into am/pm format
     */
    parseDateTime(dt) {
      var date = "N/A";
      if (dt) {
        var c_date = new Date(dt);
        var hrs = c_date.getHours();
        var min = c_date.getMinutes();
        if (isNaN(hrs) || isNaN(min) || c_date === "Invalid Date") {
          return null;
        }
        var type = hrs <= 11 ? " AM" : " PM";
        let hours = +hrs % 12 || hrs;
        if (hours.toString().length == 1) {
          hours = "0" + hours;
        }
        if (min.toString().length == 1) {
          min = "0" + min;
        }
        date = hours + ":" + min + type;
      }
      return date;
    },

    /**
     * This function is resposible for
     * fetch the facial attendance data
     * w.r.t to customize given data
     */
    getFacialData() {
      if (this.selectedOptions == "Known Members") {
        this.selectedOptions = "Known_Members";
      }
      let payload = {
        company_id: this.$route.params.id,
        start_date: this.start_date,
        end_date: this.end_date,
        user_type: this.selectedOptions.toLowerCase(),
      };
      this.loader = true;
      this.$store
        .dispatch("custom/get_facial_attendence_data", payload)
        .then((Response) => {
          this.tableView = true;
          this.chartView = false;
          this.loader = false;
          this.attendanceData = Response.data;
          this.data_availabe = true;
          if (
            this.selectedOptions == "Visitor" ||
            this.selectedOptions == "Known_Visitor" ||
            this.selectedOptions == "Member"
          ) {
            this.visitorDataOption = true;
          } else {
            this.visitorDataOption = false;
          }
          this.closeDateModal();
        })
        .catch((err) => {
          this.loader = false;
          this.closeDateModal();
        });
      this.getGraphData();
    },

    /**
     * This function is resposible for
     * fetch the facial attendance data
     * w.r.t to customize given data
     * Customize Data For Graph.
     */
    getGraphData() {
      let graphPayload = {
        company_id: this.$route.params.id,
        start_date: this.start_date,
        end_date: this.end_date,
        user_type: "all",
      };
      this.$store
        .dispatch("custom/get_facial_attendance_graph", graphPayload)
        .then((Response) => {
          var result = _(Response.data)
            .groupBy((x) => x.date)
            .map((value, key) => ({ date: key, data: value }))
            .value();
          var sortedResult = _.sortBy(result, function (o) {
            return new moment(o.date);
          });
          this.chartData = sortedResult;
          this.mapGraphData(sortedResult);
        })
        .catch((err) => {});
    },
    /**
     * This function is resposible for
     * Map Employee , Member, known_visitor
     * Visitors Counts
     * Customize Data For Graph.
     */
    mapGraphData(result) {
      this.chartoptions.xaxis.categories = [];
      this.chartoptions.series = [];
      let dates = this.chartData.map((x) => {
        return x.date;
      });
      // Map Employee Counts
      let employee = this.chartData.map((x) => {
        for (let i = 0; i < x.data.length; i++) {
          if (x.data[i].user_type == "employee") {
            return x.data[i].counts;
          }
        }
      });
      // Map Member Counts
      let members = this.chartData.map((x) => {
        for (let i = 0; i < x.data.length; i++) {
          if (x.data[i].user_type == "member") {
            return x.data[i].counts;
          }
        }
      });
      // Map Visitors Counts
      let visitors = this.chartData.map((x) => {
        for (let i = 0; i < x.data.length; i++) {
          if (x.data[i].user_type == "visitor") {
            return x.data[i].counts;
          }
        }
      });
      // Map known_visitor Counts
      let known_visitor = this.chartData.map((x) => {
        for (let i = 0; i < x.data.length; i++) {
          if (x.data[i].user_type == "known_visitor") {
            return x.data[i].counts;
          }
        }
      });
      this.removeUndefinedFromArray(
        dates,
        employee,
        members,
        visitors,
        known_visitor
      );
    },
    /**
     * This function is resposible for
     * Remove Undefined From Visitors,
     * Employee, Members, known_visitor
     * From Arrays
     */
    removeUndefinedFromArray(
      dates,
      employee,
      members,
      visitors,
      known_visitor
    ) {
      //Remove Undefined From Visitors Array
      for (let i = 0; i < visitors.length; i++) {
        if (typeof visitors[i] == "undefined") {
          visitors[i] = 0;
        }
      }
      //Remove Undefined From known_visitor Array
      for (let i = 0; i < known_visitor.length; i++) {
        if (typeof known_visitor[i] == "undefined") {
          known_visitor[i] = 0;
        }
      }
      //Remove Undefined From Employee Array
      for (let i = 0; i < employee.length; i++) {
        if (typeof employee[i] == "undefined") {
          employee[i] = 0;
        }
      }
      //Remove Undefined From Members Array
      for (let i = 0; i < members.length; i++) {
        if (typeof members[i] == "undefined") {
          members[i] = 0;
        }
      }
      this.combineAndUpdateChart(
        dates,
        employee,
        members,
        visitors,
        known_visitor
      );
    },
    /**
     * This function is resposible for
     * combine employee array with member array
     * and visitor array with known_visitor array.
     */
    combineAndUpdateChart(dates, employee, members, visitors, known_visitor) {
      //Combine/Add visitor Array Index With known_visitor Array
      for (let i = 0; i < visitors.length; i++) {
        visitors[i] = visitors[i] + known_visitor[i];
      }
      //Combine/Add Employee Array Index With Member Array
      for (let i = 0; i < employee.length; i++) {
        employee[i] = employee[i] + members[i];
      }
      this.chartoptions.xaxis.categories = dates;
      this.chartoptions.series.push({
        name: "Employees",
        data: employee,
      });
      this.chartoptions.series.push({
        name: "Visitor",
        data: visitors,
      });

      if (this.chartView) {
        this.displayChart();
      }
    },

    // Date Range Functions
    closeDateModal() {
      this.modal_2 = false;
      this.menu = false;
    },
    /**
     * This function is resposible for
     * custom date range selection
     */
    checkIfCustomDate(date, loader_state) {
      if (!loader_state) {
        this.date_loader = true;
      }
      this.dateRangeTitle = moment(date).format("MMM D, YYYY");
      if (date.id === 1) {
        let start = moment().format("YYYY-MM-DD");
        let end = moment().format("YYYY-MM-DD");
        this.dateRangeTitle = "Today";
      }
      if (date.id === 2) {
        let start = moment().subtract(1, "days").format("YYYY-MM-DD");
        let end = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.dateRangeTitle = "Yesterday";
      }
      if (date.id === 3) {
        let start = moment().subtract(6, "days").format("MMM D, YYYY");
        let end = moment().format("MMM D, YYYY");
        date.value = start + "-" + end;
        this.dateRangeTitle = "Last 7 Days";
        this.CustomeDate = true;
      }
      if (date.id === 4) {
        let start = moment().subtract(29, "days").format("MMM D, YYYY");
        let end = moment().format("MMM D, YYYY");
        date.value = start + "-" + end;
        this.dateRangeTitle = "Last 30 Days";
        this.CustomeDate = true;
      }
      if (date.id === 5) {
        let start = moment().startOf("month").format("MMM D, YYYY");
        let end = moment().endOf("month").format("MMM D, YYYY");
        date.value = start + "-" + end;
        this.dateRangeTitle = "This Month";
        this.CustomeDate = true;
      }
      if (date.id === 7) {
        let start = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("MMM D, YYYY");
        let end = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("MMM D, YYYY");
        date.value = start + "-" + end;
        this.dateRangeTitle = "Last Month";
        this.CustomeDate = true;
      }
      if (date.id === 6) {
        this.modal_2 = true;
        document.getElementById("cRange").click();
        this.CustomeDate = true;
      } else {
        this.showPicker = false;
        this.setDates(date);
        this.getFacialData();
      }
      document.getElementById("cRange").click();
    },
    checkClick() {
      let x = document.getElementById("custom-list-style");
      if (x && window.getComputedStyle(x).display != "block") {
        x.style.display = "block";
      }
    },
    setDates(date) {
      if (date.id === 1) {
        this.start_date = moment().format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 2) {
        this.start_date = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.end_date = moment().subtract(1, "days").format("YYYY-MM-DD");
      } else if (date.id === 3) {
        this.start_date = moment().subtract(6, "days").format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 4) {
        this.start_date = moment().subtract(29, "days").format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 5) {
        this.start_date = moment().startOf("month").format("YYYY-MM-DD");
        this.end_date = moment().endOf("month").format("YYYY-MM-DD");
      } else if (date.id === 7) {
        this.start_date = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.end_date = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
      }
    },
    getDataFromCustomDate() {
      this.modal_2 = false;
      this.start_date = moment(this.date[0]).format("YYYY-MM-DD");
      this.end_date = moment(this.date[1]).format("YYYY-MM-DD");
      if (this.start_date.toString() === "Invalid date") {
        let date = this.end_date.split("-");
        let year = date[0];
        let month = date[1];
        this.start_date = year + "-" + month + "-01";
        this.date[0] = year + "-" + month + "-01";
      }
      this.dateRangeTitle =
        moment(this.date[0]).format("ll") +
        " - " +
        moment(this.date[1]).format("ll");
      if (this.start_date > this.end_date) {
        this.checkIfStartDateGreater();
        this.dateRangeTitle =
          moment(this.date[1]).format("ll") +
          " - " +
          moment(this.date[0]).format("ll");
      }
      if (this.end_date < this.start_date) {
        this.start_date = this.start_date
          ? this.start_date
          : moment(this.date[1]).format("YYYY-MM-DD");
        this.end_date = moment(this.date[0]).format("YYYY-MM-DD");
      } else if (this.start_date > this.end_date) {
        this.checkIfStartDateGreater();
      }
      this.date_loader = true;
      this.getFacialData();
      this.date = "";
      this.menu = false;
    },

    checkIfStartDateGreater() {
      let etmp = "";
      let stmp = "";
      stmp = this.end_date;
      etmp = this.start_date;
      this.start_date = stmp;
      this.end_date = etmp;
    },
  },
};
</script>

<style scoped>
body {
  font-family: Roboto, sans-serif;
}

#chart {
  width: 750px;
  margin: 35px auto;
}

a {
  color: black;
}
.txt-color {
  color: #2a206a !important;
}

.assign-modal {
  overflow: hidden;
}

.select-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.select-visit-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.cursor {
  cursor: pointer;
  color: cornflowerblue;
  text-decoration: underline;
  text-decoration-color: blue;
}
/* Calendar Styling */
.calender-btn {
  width: 100% !important;
  z-index: 0.9 !important;
  align-content: right !important;
  margin-left: -0px;
}
.labels {
  margin-top: 20px;
  margin-bottom: -5px;
  color: #36454f;
  font-family: "Poppins", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.selected-date {
  border: 1px solid rgba(54, 69, 79, 0.32) !important;
  font-size: 12px !important;
  display: flex !important;
  height: 35px !important;
  padding: 9px !important;
  border-radius: 3px;
  background-color: #fff;
}
#custom-list-style .v-list-item__title {
  font-size: 12px !important;
}

#dashboard-calender span.v-btn__content i {
  padding: 19px 0 !important;
}
#apply-btn {
  background-color: #2758f6 !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 900 !important;
  font-family: "Poppins" !important;
}
@media (max-width: 1700px) and (min-width: 1600px) {
  #dashboard-calender span.v-btn__content i {
    height: 36px;
  }
}

@media (max-width: 600px) and (min-width: 320px) {
  .Date-Picker-Col {
    margin-top: -55px;
  }
}
</style>

<style>
#FacialAttendenceTable th {
  border-bottom: 1px solid #d1ddf0;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
}
</style>